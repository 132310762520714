import React, { useRef, useLayoutEffect } from 'react'
import { Loader } from 'google-maps'

const Map = () => {
  const wrapRef = useRef()

  useLayoutEffect(() => {
    const loader = new Loader('AIzaSyC8YuOIHVStB_V9BjaqNi98Vffa7vDWPVM', {})

    loader.load().then(function (google) {
      const gMap = new google.maps.Map(wrapRef.current, {
        center: {lat: -37.7991804, lng: 144.9862759},
        zoom: 16,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        gestureHandling: 'none',
      })

      new google.maps.Marker({
         position: {lat: -37.799244, lng: 144.9861632},
         map: gMap,
      })
      
    })

  }, [])

  return(
    <div ref={wrapRef} style={{width: '100%', paddingBottom: '76%', pointerEvents: 'none'}}>
    </div>
  )
}

export default Map
